import React, { useState, useEffect } from 'react';
import './Footer.css';
import mailIcon from '../assets/mail.svg';
import cvIcon from '../assets/cv.svg';
import githubIcon from '../assets/github.svg';
import cvPdf from '../assets/cv.pdf'; // Importa el archivo PDF
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

function Footer() {
  const [copied, setCopied] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('bjean.dev@gmail.com');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  /*const handleCopyPhone = () => {
    navigator.clipboard.writeText('+56 9 96901513');
    setCopiedPhone(true);
    setTimeout(() => {
      setCopiedPhone(false);
    }, 3000);
  };*/

  const handleDownloadCV = () => {
    const link = document.createElement('a');
    link.href = cvPdf; // Usa la referencia importada del archivo PDF
    link.download = 'cv.pdf';
    link.click();
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Animar solo una vez
    threshold: 0.1, // El porcentaje de visibilidad necesario para activar la animación
  });

  useEffect(() => {
    if (inView) {
      gsap.fromTo('.footer-content a, .footer-content button', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, stagger: 0.2, ease: 'power3.out' }
      );
      gsap.fromTo('.footer-text', 
        { opacity: 0 }, 
        { duration: 1, opacity: 1, delay: 1 }
      );
    }
  }, [inView]);

  return (
    <footer className="footer" ref={ref}>
      <div className="footer-content">
        <a href="https://www.linkedin.com/in/bjeandev/" target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 11.268h-3v-5.5c0-1.381-.619-2.5-2-2.5s-2 1.119-2 2.5v5.5h-3v-10h3v1.5c.619-.953 1.619-1.5 3-1.5 2.209 0 4 1.791 4 4v6z"/>
          </svg>
        </a>
        <a href="https://github.com/bjeandev" target="_blank" rel="noopener noreferrer">
          <img src={githubIcon} alt="Github" className="github" />
        </a>
        <button onClick={handleCopyEmail} style={{ cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}>
          <img src={mailIcon} alt="Mail" className="mail" />
        </button>
        <button onClick={handleDownloadCV} style={{ cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}>
          <img src={cvIcon} alt="CV" className="cv" />
        </button>
      </div>
      {copied && <div className="copied-message">Correo copiado al portapapeles</div>}
      {copiedPhone && <div className="copied-message">Teléfono copiado al portapapeles</div>}
      <div className="footer-text">© 2024 Jean Bustos. Todos los derechos reservados.</div>
    </footer>
  );
}

export default Footer;