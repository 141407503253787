import React, { useEffect } from 'react';
import ProjectCard from './ProjectCard';
import './Projects.css';
import fadafImage from '../fadaf-image.jpg';
import maquetaImage from '../maqueta-image.png';
import htmlIcon from '../assets/html.svg';
import cssIcon from '../assets/css.svg';
import jsIcon from '../assets/js.svg';
import phpIcon from '../assets/php.svg';
import csharpIcon from '../assets/csharp.svg';
import unityIcon from '../assets/unity.svg';
import { useInView } from 'react-intersection-observer';
import { gsap } from 'gsap';

function Projects() {
  const techIcons1 = [
    { src: htmlIcon, alt: 'HTML' },
    { src: cssIcon, alt: 'CSS' },
    { src: jsIcon, alt: 'JavaScript' },
    { src: phpIcon, alt: 'PHP' },
  ];
  const techIcons2 = [
    { src: csharpIcon, alt: 'CSharp' },
    { src: unityIcon, alt: 'Unity' }
  ];

  const { ref, inView } = useInView({
    triggerOnce: true, // Animar solo una vez
    threshold: 0.1, // El porcentaje de visibilidad necesario para activar la animación
  });

  useEffect(() => {
    if (inView) {
      gsap.fromTo('.project-card', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, stagger: 0.3, ease: 'power3.out' }
      );
    }
  }, [inView]);

  return (
    <div className="projects-box">
      <h1>Proyectos</h1>
      <div className="projects-container" ref={ref}>
        <ProjectCard
          className="project-card"
          title="Fadaf.cl"
          description="Desarrollo personalizado."
          link="https://fadaf.cl"
          image={fadafImage}
          techIcons={techIcons1}
        />
        <ProjectCard
          className="project-card"
          title="Maqueta Virtual"
          description="Se desarrollaron scripts, módulos y una UI para la interacción con la maqueta."
          link="#"
          image={maquetaImage}
          techIcons={techIcons2}
        />
      </div>
    </div>
  );
}

export default Projects;