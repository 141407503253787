import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png'; // Asegúrate de tener el logo en la carpeta src
import moonIcon from './assets/moon.svg'; // Importa el icono de la luna
import sunIcon from './assets/sun.svg'; // Importa el icono del sol
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer'; // Importa el componente Footer
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

function App() {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [darkMode, setDarkMode] = useState(false);

  const { ref: aboutRef, inView: aboutInView } = useInView({ triggerOnce: true });
  const { ref: projectsRef, inView: projectsInView } = useInView({ triggerOnce: true });
  const { ref: contactRef, inView: contactInView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    gsap.fromTo('.App-logo', 
      { opacity: 0, y: -50 }, 
      { duration: 1, opacity: 1, y: 0, ease: 'power3.out' }
    );
    gsap.fromTo('.header-content nav ul li', 
      { opacity: 0, y: -50 }, 
      { duration: 1, opacity: 1, y: 0, stagger: 0.2, ease: 'power3.out' }
    );
  }, []);

  useEffect(() => {
    if (aboutInView) {
      gsap.fromTo('.about-section', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, ease: 'power3.out' }
      );
    }
  }, [aboutInView]);

  useEffect(() => {
    if (projectsInView) {
      gsap.fromTo('.projects-section', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, ease: 'power3.out' }
      );
    }
  }, [projectsInView]);

  useEffect(() => {
    if (contactInView) {
      gsap.fromTo('.contact-section', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, ease: 'power3.out' }
      );
    }
  }, [contactInView]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleLogoHover = () => {
    gsap.to('.App-logo', { rotationY: 360, duration: 1, ease: 'power3.inOut' });
  };

  const handleLogoHoverOut = () => {
    gsap.to('.App-logo', { rotationY: 0, duration: 1, ease: 'power3.inOut' });
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <header className={`App-header ${showHeader ? 'show' : 'hide'}`}>
        <div className="header-content">
          <a href="https://bjean.dev">
            <img 
              src={logo} 
              className="App-logo" 
              alt="logo" 
              onMouseEnter={handleLogoHover} 
              onMouseLeave={handleLogoHoverOut} 
            />
          </a>
          <nav>
            <ul>
              <li><a href="#about">Sobre mí</a></li>
              <li><a href="#projects">Proyectos</a></li>
              <li><a href="#contact">Contacto</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <div className="App-content">
        <section id="about" ref={aboutRef} className="about-section">
          <section className='sections'>
            <About />
          </section>
        </section>
        <section id='projects' ref={projectsRef} className="projects-section">
          <section className='sections'>
            <Projects />
          </section>
        </section>
        <section id="contact" ref={contactRef} className="contact-section">
          <section className='sections'>
            <Contact />
          </section>
        </section>
      </div>
      <Footer /> {/* Agrega el componente Footer */}
      <button className="dark-mode-toggle" onClick={toggleDarkMode}>
        <img src={darkMode ? sunIcon : moonIcon} alt="Toggle Dark Mode" />
      </button>
    </div>
  );
}

export default App;