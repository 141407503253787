// ProjectCard.js
import React from 'react';
import './ProjectCard.css';

function ProjectCard({ title, description, link, image, techIcons }) {
  return (
    <div className="project-card">
      <h2>{title}</h2>
      <div className="image-container">
        <img className="principal-image" src={image} alt={`${title} preview`} />
      </div>
      <div className="text-container">
        <p>{description}</p>
      </div>
      <div className="tech-container">
        <div className="icons-container">
          {techIcons.map((icon, index) => (
            <img key={index} src={icon.src} alt={icon.alt} />
          ))}
        </div>
        <a href={link} target="_blank" rel="noopener noreferrer">Visitar proyecto</a>
      </div>
    </div>
  );
}

export default ProjectCard;