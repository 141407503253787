import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ReactComponent as Icon1 } from '../assets/reactjs.svg'; // Asegúrate de tener estos SVG en tu carpeta de assets
import { ReactComponent as Icon2 } from '../assets/gsap.svg';
import { ReactComponent as Icon3 } from '../assets/mongo.svg';
import { ReactComponent as Icon4 } from '../assets/sqlserver.svg';
import { ReactComponent as Icon5 } from '../assets/nodejs.svg';
import { ReactComponent as Icon6 } from '../assets/js.svg';
import { ReactComponent as Icon7 } from '../assets/unity.svg';
import { ReactComponent as Icon8 } from '../assets/csharp.svg';
import { ReactComponent as Icon9 } from '../assets/php.svg';

function Home() {
  useEffect(() => {
    // Animar la entrada del texto
    gsap.fromTo('.about-content h1', { opacity: 0, y: -50 }, { duration: 1.5, opacity: 1, y: 0, ease: 'power3.out' });
    gsap.fromTo('.about-content h2', { opacity: 0, y: -50 }, { duration: 1.5, opacity: 1, y: 0, ease: 'power3.out', delay: 0.2 });
    gsap.fromTo('.about-content p', { opacity: 0, y: -50 }, { duration: 1.5, opacity: 1, y: 0, ease: 'power3.out', delay: 0.4, stagger: 0.2 });

    // Animar los SVG
    gsap.to('.svg-icon', {
      x: '100vw',
      duration: 20, // Ajusta la duración para que sea más lenta
      repeat: -1,
      ease: 'none',
      modifiers: {
        x: gsap.utils.unitize(x => parseFloat(x) % window.innerWidth - 50) // Hace que los SVG reaparezcan por el otro lado
      }
    });

    gsap.fromTo('.svg-icon', 
      { y: -3 }, 
      { y: 18, duration: 1, repeat: -1, yoyo: true, ease: 'power1.inOut', stagger: 0.5 } // Ajusta la duración y el stagger para que el salto sea más visible
    );
  }, []);

  return (
    <div className="home-container">
      <div className="about-content">
        <h1>Jean Javier Bustos</h1>
        <h2>Desarrollador</h2>
        <p><strong>Estudios:</strong> Técnico en informática</p>
        <p><strong>Actualmente:</strong> Cursando penúltimo año de Ingeniería en informática</p>
        <p>
          Soy un desarrollador apasionado con habilidades en diversas tecnologías y lenguajes de programación. 
          Tengo experiencia en desarrollo web, bases de datos y soluciones de software. 
          Me encanta aprender y enfrentar nuevos desafíos en el campo de la informática.
        </p>
      </div>
        <div className="svg-container">
          <Icon1 className="svg-icon" />
          <Icon2 className="svg-icon" />
          <Icon3 className="svg-icon" />
          <Icon4 className="svg-icon" />
          <Icon5 className="svg-icon" />
          <Icon6 className="svg-icon" />
          <Icon7 className="svg-icon" />
          <Icon8 className="svg-icon" />
          <Icon9 className="svg-icon" />
        </div>
    </div>
  );
}

export default Home;