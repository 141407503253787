import React, { useState, useEffect } from 'react';
import './Contact.css'; // Importa el archivo CSS
import loadingIcon from '../assets/loading.svg';
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado de carga

  const validateInput = (input) => {
    const regex = /[`'";]/; // Simple regex to detect potentially harmful characters
    return !regex.test(input);
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Animar solo una vez
    threshold: 0.1, // El porcentaje de visibilidad necesario para activar la animación
  });

  useEffect(() => {
    if (inView) {
      gsap.fromTo('.form-group', 
        { opacity: 0, y: 50 }, 
        { duration: 1, opacity: 1, y: 0, stagger: 0.2, ease: 'power3.out' }
      );
    }
  }, [inView]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInput(name) || !validateInput(email) || !validateInput(message)) {
      setError('Input contains invalid characters.');
      return;
    }

    setLoading(true); // Inicia el estado de carga

    try {
      const response = await fetch('https://bjean.dev/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        console.log('Correo enviado exitosamente');
        setSubmitted(true);
      } else {
        const errorText = await response.text();
        setError(`Error al enviar el correo: ${errorText}`);
      }
    } catch (error) {
      setError(`Error al enviar el correo: ${error.toString()}`);
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };

  return (
    <div className="contact-container" ref={ref}>
      <h1>Contacto</h1>
      {submitted ? (
        <p className="success-message">Gracias por tu mensaje. Te responderé pronto.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nombre:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo electrónico:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={loading}>
            {loading ? (
              <img src={loadingIcon} alt="Loading" className="loader" />
            ) : (
              'Enviar'
            )}
          </button>
        </form>
      )}
    </div>
  );
}

export default Contact;